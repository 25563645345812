<script lang='ts'>
import analytics from '@igefa-e-commerce/analytics'
import {
  faSpinner,
  faTrash,
} from '@igefa-e-commerce/pattern-library-icons/fa/pro-regular-svg-icons'
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import Heading from '@igefa-e-commerce/pattern-library/src/components/Heading/Heading.svelte'
import Text from '@igefa-e-commerce/pattern-library/src/components/Text/Text.svelte'
import type { Basket } from 'domain/model/Basket.ts'
import type { ResolveInfo } from 'domain/service/resolve/types.ts'
import { apiClient } from '../../../apiClient.ts'
import { pages } from '../../../pages.ts'
import { mapBasketArticlesToProductBasketEvent } from '../../../tracking/mapBasketArticlesToProductBasketEvent.ts'
import { createBasketArticles } from '../../../utils/createBasketArticles.ts'
import ToCheckout from '../../atoms/ToCheckout/ToCheckout.svelte'
import ArticleCardHeader from '../../molecules/ArticleCardHeader/ArticleCardHeader.svelte'
import BasketTotals from '../BasketTotals/BasketTotals.svelte'

export let basket: Basket
export let resolveInfo: ResolveInfo

$: articles = createBasketArticles(basket, resolveInfo)

let isDeleting = false

async function deleteArticle(skuId: string) {
  isDeleting = true

  const res = await apiClient.basket.update(skuId, 0, 'SET', basket.email)

  isDeleting = false

  if (res.isOk()) {
    const result = res.unwrap()

    basket = result.basket
    resolveInfo = result.resolveInfo

    analytics(
      mapBasketArticlesToProductBasketEvent(
        'product_basket_removed',
        createBasketArticles(basket, resolveInfo),
      ),
    )
  }
}
</script>

<div data-testid='basket-popover'>
  {#if basket.numberOfArticles > 0}
    <Heading variant='sub'>Warenkorb ({basket.numberOfArticles} Artikel)</Heading>
    <hr/>

    <div class='article-list'>
      {#each articles as article}
        <ArticleCardHeader priceMode={basket.priceMode} {article}>
          <Button slot='action' variant='helper' disabled={isDeleting} icon={isDeleting ? faSpinner : faTrash} on:click={() => deleteArticle(article.skuId)}/>

          <Text slot='additional-info' variant='micro' marginBottom='none'>Anzahl: {article.quantity}</Text>
        </ArticleCardHeader>
      {/each}
    </div>

    <hr/>

    <div class='totals'>
      <BasketTotals {basket} {resolveInfo} refreshOnUpdate showCoupon={false} />
    </div>

    <div class='actions'>
      <Button variant='tertiary' href={pages.basket} fullWidth>Zum Warenkorb</Button>
      <ToCheckout disabled={!basket.numberOfArticles > 0} />
    </div>
  {:else}
    <div class='empty'>
      <Heading variant='sub'>Sie haben aktuell keine<br/>Artikel im Warenkorb.</Heading>
    </div>
  {/if}
</div>

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/spacers.scss';

  .actions {
      display: flex;
      gap: spacers.$space-2xs;
  }

  hr {
    border-bottom-width: 0;
  }

  .empty {
    text-align: center;
  }

  .article-list {
    max-height: 34vh;
    overflow-y: auto;
    padding-right: spacers.$space-2xs;
  }
</style>