<script lang='ts'>
import { faShoppingCart } from '@igefa-e-commerce/pattern-library-icons/fa/pro-solid-svg-icons'
import Button from '@igefa-e-commerce/pattern-library/src/components/Button/Button.svelte'
import Popover from '@igefa-e-commerce/pattern-library/src/components/Popover/Popover.svelte'
import type { Basket } from 'domain/model/Basket.ts'
import type { ResolveInfo } from 'domain/service/resolve/types.ts'
import { afterUpdate, onDestroy, onMount } from 'svelte'
import { apiClient } from '../../../apiClient'
import { basketChannel } from '../../../channels'
import type { BasketSizeUpdate } from '../../../channels/types'
import { pages } from '../../../pages.ts'
import BasketPopover from '../../organisms/BasketPopover/BasketPopover.svelte'

export let basket: Basket | undefined
export let resolveInfo: ResolveInfo | undefined

let isMobile = false
let open = false

$: numberOfArticles = basket?.numberOfArticles || 0

const channel = new BroadcastChannel(basketChannel.name)

const handleBasketUpdate = (event: MessageEvent) => {
  const basketSizeUpdate = event.data as BasketSizeUpdate

  if ('size' in basketSizeUpdate) {
    numberOfArticles = basketSizeUpdate.size
  }

  fetchBasket()
}

onMount(() => {
  if (typeof window !== 'undefined') {
    channel.addEventListener('message', handleBasketUpdate)
  }
})

onDestroy(() => {
  if (typeof window !== 'undefined') {
    channel.removeEventListener('message', handleBasketUpdate)
  }
})

afterUpdate(() => {
  isMobile = window.innerWidth <= 768
})

async function fetchBasket() {
  const res = await apiClient.basket.resolve()

  if (res.isOk()) {
    const data = res.unwrap()

    basket = data.basket
    resolveInfo = data.resolveInfo
  }
}
</script>

{#if isMobile}
  <div class='icon' data-testid='basket-mini-basket'>
    <Button icon={faShoppingCart} iconType="top" variant='tertiary' href={pages.basket}/>

    {#if numberOfArticles > 0}
      <span class='basket-icon-badge'>{numberOfArticles}</span>
    {/if}
  </div>
{:else}
  <div class:popover={open} on:mouseleave={() => open = false}>
    <Popover placement="bottom" {open} class='basket-popover' on:close={() => open = false}>
      <div class='icon' data-testid='basket-mini-basket' slot='target'>
        <Button on:mouseenter={() => {
          // TODO: There are still placement issues and we need to update visible
          // pages (e.g. Basket, Checkout) if we remove articles via the popover.
          // Once this is working, we can set `open=true`.
          // open = true
        }} on:click={() => window.location = pages.basket}
                iconType="top" variant='tertiary' icon={faShoppingCart}
        />

        {#if numberOfArticles > 0}
          <span class='basket-icon-badge'>{numberOfArticles}</span>
        {/if}
      </div>

      <svelte:fragment slot='popover'>
        {#if basket && resolveInfo}
          <BasketPopover bind:basket bind:resolveInfo />
        {/if}
      </svelte:fragment>
    </Popover>
  </div>
{/if}

<style lang='scss'>
  @use '@igefa-e-commerce/pattern-library/src/css/colors.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/fonts.scss';
  @use '@igefa-e-commerce/pattern-library/src/css/spacers.scss';

  .icon {
    position: relative;
    width: fit-content;
    height: fit-content;

    .basket-icon-badge {
      position: absolute;
      background-color: colors.$color_helper_sale;
      color: white;
      font-size: 0.7rem;
      padding: 0 0.4em;
      border-radius: 2em;
      text-align: center;
      top: -6px;
      right: -6px;
      height: 20px;
      line-height: 20px;
      min-width: 20px;
      box-sizing: border-box;
      max-width: 38px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .popover {
    padding-bottom: 1em;
    margin-bottom: -1em;
  }
</style>
